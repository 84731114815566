import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import MainVideoPage from "./videoComponents/MainVideoPage";
import ProDashboard from "./siteComponents/ProDashboard";
import ProMainVideoPage from "./videoComponents/ProMainVideoPage";
import LoginPage from "./siteComponents/LoginPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={LoginPage} />
                <Route exact path="/" Component={LoginPage} />
                <Route path="/condominio" Component={MainVideoPage} />
                <Route exact path="/operador" Component={ProMainVideoPage} />
                {/* <Route exact path="/dashboard" Component={ProDashboard} /> */}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
