// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-login {
    max-width: 400px;
    width: 100%;
    object-fit: cover;
}

.card-login {
    min-width: 500px;
    background-color: rgb(177, 177, 177);
}
`, "",{"version":3,"sources":["webpack://./src/siteComponents/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,oCAAoC;AACxC","sourcesContent":[".logo-login {\n    max-width: 400px;\n    width: 100%;\n    object-fit: cover;\n}\n\n.card-login {\n    min-width: 500px;\n    background-color: rgb(177, 177, 177);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
