import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import "./VideoComponents.css";

import addStream from "../redux-elements/actions/addStream";
import updateCallStatus from "../redux-elements/actions/updateCallStatus";

import createPeerConnection from "../webRTCutilities/createPeerConnection";
import socketConnection from "../webRTCutilities/socketConnection";
import proSocketListeners from "../webRTCutilities/proSocketListeners";

import startLocalVideoStream from "./VideoButton/startLocalVideoStream";

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProMainVideoPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const callStatus = useSelector((state) => state.callStatus);
    const streams = useSelector((state) => state.streams);
    const [userInfo, setUserInfo] = useState({});
    const smallFeedEl = useRef(null);
    const largeFeedEl = useRef(null);
    const [haveGottenIce, setHaveGottenIce] = useState(false);
    const streamsRef = useRef(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [startCall, setStartCall] = useState(false);
    const [userId, setUserId] = useState("");
    const userIdRef = useRef(null);

    const refreshPage = () => {
        window.location.reload();
    };

    useEffect(() => {
        userIdRef.current = userId;
    }, [userId]);

    useEffect(() => {
        const videoElement = largeFeedEl.current;

        if (videoElement) {
            const handlePlay = () => {
                setIsStreaming(true);
            };

            const handlePause = () => {
                setIsStreaming(false);
            };

            const handleEnded = () => {
                setIsStreaming(false);
            };

            videoElement.addEventListener("playing", handlePlay);
            videoElement.addEventListener("pause", handlePause);
            videoElement.addEventListener("ended", handleEnded);

            return () => {
                videoElement.removeEventListener("playing", handlePlay);
                videoElement.removeEventListener("pause", handlePause);
                videoElement.removeEventListener("ended", handleEnded);
            };
        }
    }, []);

    useEffect(() => {
        const fetchMedia = async () => {
            const constraints = {
                video: true,
                audio: false,
            };
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                dispatch(addStream("localStream", stream));

                const { peerConnection, remoteStream } = await createPeerConnection(addIce);
                dispatch(addStream("remote1", remoteStream, peerConnection));
                dispatch(updateCallStatus("haveMedia", true));

                largeFeedEl.current.srcObject = remoteStream;
                smallFeedEl.current.srcObject = stream;
            } catch (err) {
                console.error(err);
            }
        };
        fetchMedia();
    }, []);

    useEffect(() => {
        if (callStatus.haveMedia) {
            startLocalVideoStream(streams, dispatch);
        }
    }, [callStatus.haveMedia]);

    useEffect(() => {
        const getIceAsync = async () => {
            const socket = socketConnection(localStorage.getItem("token"));
            const iceCandidates = await socket.emitWithAck("getIce", userId, "professional");
            iceCandidates.forEach((iceC) => {
                for (const s in streams) {
                    if (s !== "localStream") {
                        const pc = streams[s].peerConnection;
                        pc.addIceCandidate(iceC);
                    }
                }
            });
        };
        if (streams.remote1 && !haveGottenIce) {
            setHaveGottenIce(true);
            getIceAsync();
            streamsRef.current = streams;
        }
    }, [streams, haveGottenIce]);

    useEffect(() => {
        const setAsyncOffer = async () => {
            for (const s in streams) {
                if (s !== "localStream") {
                    const pc = streams[s].peerConnection;
                    await pc.setRemoteDescription(callStatus.offer);
                }
            }
        };
        if (callStatus.offer && streams.remote1 && streams.remote1.peerConnection) {
            setAsyncOffer();
        }
    }, [callStatus.offer, streams.remote1]);

    useEffect(() => {
        const createAnswerAsync = async () => {
            for (const s in streams) {
                if (s !== "localStream") {
                    const pc = streams[s].peerConnection;
                    const answer = await pc.createAnswer();
                    await pc.setLocalDescription(answer);
                    dispatch(updateCallStatus("haveCreatedAnswer", true));
                    dispatch(updateCallStatus("answer", answer));
                    const token = localStorage.getItem("token");
                    const socket = socketConnection(token);

                    socket.emit("newAnswer", { answer, user_id: userId });
                }
            }
        };
        if (startCall && !callStatus.haveCreatedAnswer) {
            createAnswerAsync();
        }
    }, [startCall, callStatus.haveCreatedAnswer]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const fetchDecodedToken = async () => {
            const resp = await axios.post(`https://${baseUrl}/signaling/validate-link`, { token });
            setUserInfo(resp.data);
        };
        if (token) {
            fetchDecodedToken();
        } else {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const socket = socketConnection(token);
        proSocketListeners.proDashabordSocketListeners(socket, setUserInfo, dispatch, setUserId, setStartCall);
        proSocketListeners.proVideoSocketListeners(socket, addIceCandidateToPc, refreshPage);
    }, []);

    const addIceCandidateToPc = (iceC) => {
        for (const s in streamsRef.current) {
            if (s !== "localStream") {
                const pc = streamsRef.current[s].peerConnection;
                pc.addIceCandidate(iceC);
            }
        }
    };

    const addIce = (iceC) => {
        const socket = socketConnection(localStorage.getItem("token"));
        socket.emit("iceToServer", {
            iceC,
            who: "professional",
            user_id: userIdRef.current,
        });
    };

    const logout = () => {
        localStorage.clear();
        refreshPage();
    };

    return (
        <div className="main-video-page">
            <div className="info-header">
                <div className="info-header-content">Operador {userInfo && userInfo.ramal ? <> - Ramal {userInfo.ramal}</> : <></>}</div>
                <div className="logout-text" onClick={logout}>
                    Sair
                </div>
            </div>
            <video id="own-feed" ref={smallFeedEl} autoPlay playsInline hidden={!isStreaming}></video>
            <video id="large-feed-cond" ref={largeFeedEl} autoPlay playsInline hidden={!isStreaming}></video>
            <div className="operator-content">
                <div className="logo-container" >
                    <img src="https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png" alt="Logo" className="logo" />
                </div>
            </div>
            {/* <ActionButtons smallFeedEl={smallFeedEl} largeFeedEl={largeFeedEl} /> */}
        </div>
    );
};

export default ProMainVideoPage;
