import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErroMessage] = useState("");
    const [apptInfo, setApptInfo] = useState({});
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErroMessage("");
        try {
            const response = await fetch(`https://${baseUrl}/api/auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();
            if (response.ok) {
                
                localStorage.setItem("token", data.token);
                setToken(data.token);
            } else {
                setErroMessage(data.message);
            }
        } catch (error) {
            console.error("Erro:", error);
        }
    };

    useEffect(() => {
        setToken(localStorage.getItem("token"));
    });

    useEffect(() => {
        const fetchDecodedToken = async () => {
            const resp = await axios.get(`https://${baseUrl}/api/auth/user-info`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setApptInfo(resp.data);
        };
        if (token) {
            fetchDecodedToken();
        }
    }, [token]);

    useEffect(() => {
        if (apptInfo && apptInfo.user_type) {
            if (apptInfo.user_type === "condominio") {
                navigate("/condominio");
            } else if (apptInfo.user_type === "operador") {
                navigate("/operador");
            }
        }
    }, [apptInfo]);

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 shadow-lg card-login">
                {/* <div className="text-center mb-4 logo-login">
                    <img
                        src="https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png"
                        alt="Logo"
                        className="img-fluid"
                        style={{ maxHeight: "100px" }}
                    />
                </div> */}
                {/* <h2 className="text-center mb-4">Login</h2> */}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                            Username:
                        </label>
                        <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            Senha:
                        </label>
                        <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="text-danger text-center">
                        {errorMessage !== "" ? (
                            <>
                                {errorMessage}
                                <p></p>{" "}
                            </>
                        ) : null}
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                        Entrar
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;
