import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL || "wss://localhost", {
  path: "/socket/",
  autoConnect: false,
});

const socketConnection = (jwt) => {
  //nao funcionando
  if (socket && socket.connected) {
    return socket;
  } else {
    socket.auth = { jwt };

    socket.connect();
  }

  return socket;
};

export default socketConnection;